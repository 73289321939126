.slider-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.upper-box {
  text-align: center;
  padding: 60px;
  width: 90%;
  margin-left: 5%;
  background: linear-gradient(90deg, rgba(200, 3, 70, 1) 30%, rgba(249, 157, 64, 1) 77%);
}

.slider {
  display: flex;
  overflow: hidden;
  width: 300%;
  margin-bottom: -13%;
}

.slider-wrapper {
  display: flex;
  width: calc(100% * 24); /* 12 slides * 2 to ensure seamless looping */
  animation: scroll 24s linear infinite;
}

@keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); } /* Adjusted to loop back after half of the width */
}

.slide {
  width: 70%;
  box-sizing: border-box;
}

.slide img {
  width: 90%;
  height: 40%;
  margin-top: 14%;
}

.upper-box p {
  margin-top: 3%;
}

.upper-box h2 {
  /* background: linear-gradient(90deg, rgba(200,3,70,1) 30%, rgba(249,157,64,1) 77%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: aliceblue;
}
/* Responsive styles */
@media (max-width: 1024px) {
  .upper-box {
    padding: 50px;
  }

  .slider {
    width: 300%;
  }

  .slide img {
    width: 85%;
    height: 85%;
  }
}

@media (max-width: 768px) {
  .upper-box {
    padding: 40px;
    width: 95%;
    margin-left: 2.5%;
  }

  .slider {
    width: 485%;
  }

  .slide img {
    width: 80%;
    height: 80%;
    margin-top: 10%;
  }
}

@media (max-width: 567px) {
  .upper-box {
    padding: 30px;
    width: 100%;
    margin-left: 0;
  }
  .upper-box h2{
    font-size: 26px;
  }
  .upper-box p{
    font-size: 14px;
  }

  .slider {
    width: 820%;
  }
  .slide img {
    height: 50%;
  }
}