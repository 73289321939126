
   .menu-bg .navbar-nav .nav-link {
    color: #ffffff !important;
}

.menu-bg .navbar-nav .nav-link:hover {
    color: #ffffff !important;
}

.menu-bg .navbar-nav .active {
    color: #ffffff !important;
}

.navbar-expand-md .navbar-nav .nav-link {
    font-size: 16px;
    padding: 8px 0px;
    margin-left: 26px;
    font-family: 'Poppins', sans-serif;
    position: relative;
    font-weight: 500;
}

.navbar-expand-md .navbar-nav .nav-link:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: #ffffff;
    transition: 0.3s;
}

.navbar-expand-md .navbar-nav .active::before,
.navbar-expand-md .navbar-nav .nav-link:hover::before {
    width: 100%;
}

.navbar-expand-md .navbar-toggler {
    background: transparent;
    border: 1px solid #fff;
    border-radius: 3px;
    margin: 5px;
    cursor: pointer;
    float: right;
}

.navbar-expand-md .navbar-toggler:focus {
    outline: none;
}

   /* Hero Area */

.hero-area {
    position: relative;
}
.hero-area .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, rgba(200, 3, 70, 1) 30%, rgba(249, 157, 64, 1) 77%);
    transform: skewY(-12deg);
    transform-origin: 0;
    z-index: -12;
}
.hero-area .overlay span:nth-child(1) {
    width: 33.33333%;
    top: 0;
    left: 16.66666%;
    background: linear-gradient(90deg, rgba(4, 8, 54, 1) 1%, rgba(34, 39, 93, 1) 49%, rgba(78, 133, 215, 1) 100%);
    height: 200px;
}
.hero-area .overlay span:nth-child(2) {
    width: 33.33333%;
    bottom: 0;
    background: linear-gradient(90deg, rgba(69, 1, 9, 1) 0%, rgba(150, 7, 21, 1) 50%, rgba(249, 157, 64, 1) 82%);
    position: absolute;
    height: 155px;
    left: 3%;
}
.hero-area .contents {
    padding: 220px 0;
    margin-bottom: 13%;
    margin-top: -8%;
}
.contents h2 {
    font-family: "Nerko One", cursive;
    font-size: 38px;
    font-weight: 600;
    line-height: 60px;
    margin-bottom: 20px;
}
.contents p {
    font-size: 18px;
    line-height: 26px;
}
.header-button .btn {
    margin-top: 40px;
    margin-right: 20px;
    text-transform: uppercase;
    width: 180px;
    height: 50px;
    padding: 15px 15px;
}
.hero-area .contents .btn-border {
    border: 1px solid #fff;
    color: #3ecf8e !important;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    width: 180px;
    height: 50px;
    padding: 15px 15px;
    background: #fff;
}
.hero-area .contents .btn-border:hover {
    background: #fff;
    color: #3ecf8e;
}
.hero-area .intro-img {
    padding: 180px 0 0px;
}
.logo {
    height: 50px; /* Adjust the height as needed */
    width: auto;
}
.hero-area .intro-img img {
    max-width: 100%;
    margin-left: 9%;
    margin-top: 9%;
}
.tv-container {
    position: relative;
    width: 100%;
    max-width: 600px; /* Adjust the size according to your design */
    margin: 0 auto;
}
.background-video {
    position: absolute;
    top: 87px;
    left: 159px;
    width: 64%;
    height: 44%;
    object-fit: cover;
    z-index: 1;
}
.tv-image {
    position: relative;
    z-index: 2;
    width: 100%;
    height: auto;
}

/* Responsive Styles */

@media (max-width: 1024px) {
    .hero-area .contents {
        padding: 180px 0;
    }
    .hero-area .contents h2 {
        font-size: 34px;
        line-height: 50px;
    }
    .hero-area .contents .btn {
        width: 160px;
        height: 45px;
        padding: 13px;
    }
    .background-video {
        top: 70px;
        left: 140px;
        width: 60%;
        height: 40%;
    }
}

@media (max-width: 991px) {
    .navbar-expand-md .navbar-nav .nav-link {
        margin-left: 16px;
    }
    .hero-area .contents {
        padding: 150px 0;
    }
    .hero-area .contents h2 {
        font-size: 30px;
        line-height: 45px;
    }
    .background-video {
        top: 60px;
        left: 130px;
        width: 58%;
        height: 38%;
    }
}

@media (max-width: 768px) {
    .hero-area .contents {
        padding: 52px 0;
        margin-top: 16%;
        margin-bottom: -27%;
    }
    .hero-area .contents h2 {
        font-size: 24px;
        margin-left: 0%;
    }
    .contents p {
        font-size: 15px;
        margin-left: 0%;
    }
    .hero-area .intro-img img {
        margin-left: 32%;
        margin-top: -4%;
        width: 77%;
    }
    .background-video {
        top: 10px;
        left: 230px;
        width: 50%;
        height: 49%;
    }
    .tv-container {
        max-width: 90%;
    }
   
}
@media (max-width: 567px) {
    .hero-area .contents {
        padding: 52px 0;
        margin-top: 17%;
        margin-bottom: -43%;
    }
    .hero-area .contents h2 {
        margin-left: 0%;
        font-size: 22px;
    }
    .contents p {
        font-size: 15px;
        margin-left: 0%;
    }
    .hero-area .intro-img img {
        margin-left: 29%;
        margin-top: -15%;
        width: 80%;
    }
    .background-video {
        top: -31px;
        left: 152px;
        width: 50%;
        height: 62%;
    }
    .tv-container {
        max-width: 90%;
    }
}