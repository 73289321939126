
   @import '../styles/responsive.css';
   @import url("https://fonts.googleapis.com/css?family=Rubik:400,400i,500");
   @import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600");
   body {
     font-family: 'Rubik', sans-serif;
     color: #a0a6ad;
     font-size: 14px;
     font-weight: 400;
     background: #080808;
     overflow-x: hidden; }
   
   html {
     overflow-x: hidden; }

   p {
     font-size: 14px;
     color: #5e629c;
     line-height: 26px;
     margin-bottom: 0; }
   
   a:hover, a:focus {
     color: #000; }
   
   a {
     color: #000;
     -webkit-transition: all 0.3s ease-in-out;
     -moz-transition: all 0.3s ease-in-out;
     -o-transition: all 0.3s ease-in-out;
     transition: all 0.3s ease-in-out; }
   
   h1, h2, h3, h4, h5 {
     font-family: 'Poppins', sans-serif;
     font-size: 40px;
     font-weight: 400;
     color: #151948; }
   
   ul {
     margin: 0;
     padding: 0; }
   
   ul li {
     list-style: none; }
   
   a:hover,
   a:focus {
     text-decoration: none;
     outline: none; }
   
   a:not([href]):not([tabindex]) {
     color: #fff; }
   
   a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
     color: #fff; }
   
   .bg-transparent {
     background: transparent !important; }
   
   .bg-defult {
     background: #3c9cfd !important; }
   
   /* ==========================================================================
      Section Title
      ========================================================================== */
   .section {
     padding: 130px 0px; }
   
   .form-control:focus {
     outline: none;
     box-shadow: none; }
   
   .section-header {
     color: #fff;
     margin-bottom: 50px;
     text-align: center;
     position: relative; }
   
   .section-header .section-title {
    line-height: 81px;
    margin-top: -5%;
    }
   
   .section-header .desc-text p {
     font-size: 18px;
     line-height: 26px;
     margin-bottom: 0; }
   
   /* ==========================================================================
      Buttons
      ========================================================================== */
   .btn {
     font-size: 14px;
     padding: 10px 30px;
     border-radius: 4px;
     font-family: 'Rubik', sans-serif;
     letter-spacing: 1px;
     font-weight: 500;
     color: #fff;
     border: none;
     text-transform: uppercase;
     -webkit-transition: all 0.3s ease-in-out;
     -moz-transition: all 0.3s ease-in-out;
     -o-transition: all 0.3s ease-in-out;
     transition: all 0.3s ease-in-out;
     display: inline-block; }
   
   .btn:focus,
   .btn:active {
     box-shadow: none;
     outline: none; }
   
   .btn-common {
     background-color: #e98f1a;
     position: relative;
     z-index: 1;
     box-shadow: 0px 8px 9px 0px rgba(96, 94, 94, 0.17);
     width: 160px;
     height: 50px;
     margin-left: 40%;
     padding: 14px 15px; }
   
   .btn-common:hover {
     color: #fff;
     transform: translateY(-2px); }
   
   .btn-light-bg {
     background-color: #ff704f; }
   
   .btn-border {
     border-style: solid;
     border-width: 1px;
     cursor: pointer;
     background-color: transparent;
     border-color: #3ecf8e;
     color: #3ecf8e;
     width: 160px;
     height: 50px;
     padding: 14px 15px; }
   
   .btn-border:hover {
     box-shadow: 0px 8px 9px 0px rgba(96, 94, 94, 0.17);
     transform: translateY(-2px); }
   
   .btn-border-filled {
     cursor: pointer;
     background-color: #3ecf8e;
     border: 1px solid #3ecf8e;
     color: #fff;
     box-shadow: 0px 8px 9px 0px rgba(96, 94, 94, 0.17);
     width: 180px;
     height: 50px;
     padding: 15px 15px; }
   
   .btn-border-filled:hover {
     color: #fff;
     background-color: transparent;
     border-color: #fff;
     transform: translateY(-2px); }
   
   .btn-lg {
     padding: 14px 33px;
     text-transform: uppercase;
     font-size: 16px; }
   
   .btn-rm {
     color: #3c9cfd;
     padding: 10px 0;
     text-transform: capitalize;
     font-size: 16px; }
   
   .btn-rm i {
     vertical-align: middle; }
   
   .btn-singin {
     background: #7fc9fb;
     color: #fff;
     padding: 10px 23px;
     margin-left: 30px;
     box-shadow: 0px 8px 9px 0px rgba(96, 94, 94, 0.17); }
   
   .btn-singin:hover,
   .btn-singin:active,
   .btn-singin:focus {
     color: #fff;
     box-shadow: 0px 0px 0px 0px rgba(96, 94, 94, 0.17); }
   
   .btn-light-singin:hover,
   .btn-light-singin:active,
   .btn-light-singin:focus {
     color: #fff;
     box-shadow: 0px 0px 0px 0px rgba(96, 94, 94, 0.17); }
   
   .btn-apple {
     border-width: 2px;
     border-color: #3c96ff;
     border-style: solid;
     background: linear-gradient(to right, #3c96ff 0%, #2dfbff 100%);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     transition: all 0.3s ease-in-out;
     -moz-transition: all 0.3s ease-in-out;
     -webkit-transition: all 0.3s ease-in-out;
     -o-transition: all 0.3s ease-in-out; }
   
   .btn-apple i {
     background: linear-gradient(to right, #3c96ff 0%, #2dfbff 100%);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent; }
   
   .btn-apple:hover,
   .btn-apple:active,
   .btn-apple:focus {
     background: linear-gradient(to right, #3c96ff 0%, #2dfbff 100%) !important;
     -webkit-background-clip: text !important;
     -webkit-text-fill-color: transparent !important; }
   
   button:focus {
     outline: none !important; }
   
   .btn-border-fill {
     background: #fff;
     border-color: #fff;
     color: #3c9cfd; }
   
   .btn-subtitle {
     color: #191c1e;
     font-family: 'Poppins', sans-serif;
     background: #dbecff;
     text-align: center;
     border-radius: 30px;
     padding: 6px 30px;
     text-transform: uppercase;
     font-weight: 700;
     margin-bottom: 20px;
     font-size: 14px; }
   
   .clear {
     clear: both; }
   
   .mt {
     margin-top: 80px; }
   
   .mt-30 {
     margin-top: 30px; }
   
   .mb-30 {
     margin-bottom: 30px; }
   
   .pt-30 {
     padding-top: 30px; }
   
   .pb-30 {
     padding-bottom: 30px; }
   
   .pt-70 {
     padding-top: 70px; }
   
   .mb-50 {
     margin-bottom: 50px; }
   
   .padding-0 {
     padding: 0; }
   
   .pr-20 {
     padding-right: 20px; }
   
   .pl-20 {
     padding-left: 20px; }
   
   .pl-70 {
     padding-left: 70px; }
   
   .pr-70 {
     padding-right: 70px; }
   
  

/* ==========================================================================
      About 
      ========================================================================== */
   #services .services-item {
     padding: 60px 30px;
     background-color: #000;
     border: 1px solid #ddd;
     -moz-transition: all .8s ease;
     -webkit-transition: all .8s ease;
     transition: all .8s ease; }
     #services .services-item:hover {
       border: 1px solid #f7bb04;
       box-shadow: 0px 0px 25px 0px rgba(95, 95, 95, 0.24);
       -moz-transition: all .4s ease;
       -webkit-transition: all .4s ease;
       transition: all .4s ease; }
       #services .services-item .icon-ima {
        background: none;
        width: 139px;
        height: 132px;
        margin: 0 auto 25px auto;
        text-align: cente   r;
        display: block;
        border-radius: 17%;
        object-fit: cover;
        margin-top: -19%;}
     #services .services-item h4 {
       font-size: 20px;
       font-weight: 600;
       color: #151948;
       margin-bottom: 15px; }
     #services .services-item p {
       font-size: 16px;
       margin-bottom: 10px;
       color: #5e629c; }
   /*details*/
   .detail-card {
    background-color: #000;
     border: 1px solid #e40f0f;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
  }
  
  .detail-card:hover {
    border: 1px solid #f7bb04;
    transform: translateY(-10px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .detail-icon {
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin-bottom: 20px;
    transition: filter 0.3s ease;
  }
  
  .detail-icon:hover {
    filter: brightness(0) saturate(100%) invert(70%) sepia(100%) saturate(500%) hue-rotate(0deg) brightness(100%) contrast(100%);
}
   /* ==========================================================================
      business-plan Section 
      ========================================================================== */
     #business-plan .container-fluid {
       padding-right: 30px;
       padding-left: 30px; }
     #business-plan .business-item-info {
       margin-top: 60px; }
       #business-plan .business-item-info h3 {
         font-size: 35px;
         font-weight: 600;
         background: linear-gradient(90deg, rgba(200,3,70,1) 30%, rgba(249,157,64,1) 77%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
         margin-bottom: 25px; }
       #business-plan .business-item-info p {
         font-size: 16px;
         margin-bottom: 20px; }
   
   /* ==========================================================================
      Features 
      ========================================================================== */
   /* Background and margin for features section */
#features {
  background: #0c0b0b;
  margin: 10% auto;
  padding: 2% 0; /* Add some padding to the top and bottom */
}
.features-h2 {
  background: linear-gradient(90deg, rgba(200,3,70,1) 30%, rgba(249,157,64,1) 77%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
}

/* Border styles for feature items */
#features .featured-border1 {
  border: 1px solid #ddd;}
#features .featured-border2 {
  border: 1px solid #ddd;}
#features .featured-border3 {
  border: 1px solid #ddd;}

/* Styling for each feature item */
#features .feature-item {
  background: #1e1e1e;
  border-radius: 8px;
  padding: 0px;
  margin: 9px;
  height: 153px;
  text-align: center;
  transition: box-shadow 0.3s, transform 0.3s;
  width: 425px;
  margin-left: -8%;
}
/* Feature icon styling */
#features .feature-item .feature-icon {
  background: #9ee577;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0px auto 15px;
    border: 1px solid #3ecf8e;
    margin-left: 78%;
}

#features .feature-item .feature-icon img {
  max-width: 60px; /* Adjust image size as needed */
  height: auto;
}
#features .feature-item h4 {
  font-weight: 600;
  margin-bottom: 8px;
  margin-top: -83px;
  margin-left: 2%;
}
#features .feature-item p {
  font-size: 14px;
  margin-left: 2%;
}

/* Hover effect for feature items */
#features .feature-item:hover {
  box-shadow: 0 4px 12px rgba(0,0,0,0.3);
  transform: scale(1.05);
}

#features .feature-item:hover .feature-icon {
  background: #3ecf8e;
}

#features .feature-item:hover .feature-icon img {
  filter: invert(1); /* Inverts color for the image on hover */
}

      /* Testimonial section */

          
          .right-position {
            position: relative;
            margin-top: -11%;
            margin-bottom: -7%;
        }
          
          /* Video styling */
          .video-promo-content {
            margin-bottom: 30px;
          }
          
          #promo-video {
            width: 100%;
            max-width: 1000px;
            border-radius: 10px;
          }
          
          /* Testimonial area */
          .testimonial-area {
            padding: 20px;
            border-radius: 10px;
            width: 115%;
            margin-left: -7%;
            margin-top: -5%;
        }
          
          .testimonial-item {
            background: linear-gradient(90deg, rgba(200, 3, 70, 1) 30%, rgba(249, 157, 64, 1) 77%);
            padding: 20px;
            border-radius: 10px;
          }
          
          .description {
            font-size: 16px;
            line-height: 1.6;
            margin-bottom: 20px;
          }
          
          .author-info h5 {
            margin-top: 10px;
            font-size: 18px;
            font-weight: bold;
          }
          
          .author-info span {
            color: #ddd;
          }
          
          /* Owl carousel */
          .owl-carousel .item {
            padding: 20px;
          }
          
          .owl-carousel .owl-dots {
            margin-top: 30px;
            text-align: center;
          }
          
       
       @keyframes pulse-border {
         0% {
           transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
           opacity: 1; }
         100% {
           transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
           opacity: 0; } }
   /* ==========================================================================
      Our Pricing 
      ========================================================================== */
    .container1 {
      margin-top: -16;
    }

    .col-lg-4 {
      flex: 0 0 auto;
      width: 30.333333%;
      margin-left: 33px;
  }

      #pricing .pricing-table {
     padding: 15px;
     height: 101%;
     border: 1px solid #ccc;
     -moz-transition: all .8s ease;
     -webkit-transition: all .8s ease;
     transition: all .8s ease; 
    }
     #pricing .pricing-table h3 {
       font-size: 20px;
       color: #151948;
       font-weight: 500;
       text-transform: uppercase;
       margin-bottom: 11px; }
       #pricing .pricing-table h1 {
        color: #3ecf8e;
        font-size: 31px;
        position: relative;
    }
    #pricing .pricing-table h1 span {
      font-size: 28px;
      position: absolute;
      left: 29%;
      top: 0px;
  }
     #pricing .pricing-table ul {
       padding-bottom: 28px; }
       #pricing .pricing-table ul li {
        text-align: left;
        font-size: 15px;
        color: #5e629c;
        display: block;
    }
     #pricing .pricing-table:hover {
      border: 1px solid #f7bb04;
       box-shadow: 0px 0px 25px 0px rgba(95, 95, 95, 0.24);
       -moz-transition: all .4s ease;
       -webkit-transition: all .4s ease;
       transition: all .4s ease; }
   
   /* ==========================================================================
   Contact section
   ========================================================================== */
   #contact {
    padding: 3rem 0; /* Added padding for better spacing */
  }
  
  #contact .section-header {
    margin-bottom: 2rem;
  }
  
  #contact .section-title {
    font-size: 2rem; /* Responsive font size */
    line-height: 1.2;
  }
  
  #contact .desc-text {
    margin-top: 1rem;
  }
  
  /* Form Styling */
  #contact form#contactForm .form-group {
    margin-bottom: 1.5rem; /* Reduced margin for better spacing */
  }
  
  #contact form#contactForm input[type=text],
  #contact form#contactForm input[type=email],
  #contact form#contactForm textarea {
    width: 100%;
    min-height: 52px;
  }
  
  #contact form#contactForm .form-control:focus {
    border: 1px solid #3ecf8e;
    background: #c6f0dd;
  }
  
  /* Video Styling */
  .contact-video {
    text-align: center; /* Center the video */
  }
  
  .contact-video video {
    width: 100%; /* Ensures the video scales with the container */
    height: auto;
    display: block;
  }
  
  