@media (max-width: 1024px) {
  .contact-img {
    margin-left: 272px;
    margin-top: -22%;}
  .col-lg-4 {
    flex: -8 0 auto;
    width: 41.333333%;
    margin-left: 60px;}
  #features .feature-item {
    width: 437px;
    margin-left: -35px;}
  #business-plan .business-item-info h3 {
    font-size: 28px; }
  #business-plan .business-item-info p {
    font-size: 13px;
    margin-bottom: 20px; }
  .section {
    padding: 80px 0px; }
  #business-plan {
    padding-bottom: 80px; }
  #showcase .gradient-bg {
    padding: 80px 0px 60px 0px; }
  #pricing {
    padding-bottom: 80px; }
  #testimonial #client-testimonial .content-inner p {
    font-size: 18px;
    font-style: italic;
    line-height: 35px; }
  
    #promo-video {
      height: 400px;
    }
  
    .testimonial-item {
      padding: 15px;
    }
  
    .description {
      font-size: 15px;
    }
  }

@media (max-width: 991px) {
  #features .feature-item {
    width: 356px;
    margin-left: 6px;}
    .col-lg-4 {
      width: 50.333333%;
      margin-left: -3px;}
  #pricing .pricing-table {
    padding: 30px 15px; }
  #pricing .pricing-table h1 span {
    left: 10%; }
  #team .single-team, .blog-item-wrapper, .services-item {
    margin-bottom: 20px; }
  .contact-img img {
    margin-left: -83px;
        margin-top: 110px;
}
  .btn-singin {
    padding: 10px 14px;
    margin-left: 12px;
    box-shadow: 0px 8px 9px 0px rgba(96, 94, 94, 0.17);
    font-size: 11px; }
  .btn-light-singin {
    padding: 10px 14px;
    margin-left: 12px;
    box-shadow: 0px 8px 9px 0px rgba(96, 94, 94, 0.17);
    font-size: 11px; }
  .navbar-expand-md .navbar-nav .nav-link {
    padding: 12px 0px;
    font-size: 13px;
    margin-left: 9px; }
  .pt-70 {
    padding-top: 20px; }
    #promo-video {
      height: 350px;
    }
  
    .description {
      font-size: 14px;
    }
  
    .author-info h5 {
      font-size: 16px;
    } }

@media (max-width: 768px) {
  #promo-video {
    height: 300px;
  }

  .testimonial-area {
    padding: 30px;
  }

  .testimonial-item {
    padding: 10px;
  }

  .description {
    font-size: 13px;
  }

  .author-info h5 {
    font-size: 14px;
  }
  
#services .services-item {
    padding: 100px 18px;
}
  #features .feature-item .feature-icon {
    margin-left: 84%;
    margin-top: 4px;
}
.btn-common {
  margin-left: 34%;
}
  .contact-img {
    margin-left: 220px;
    margin-top: -32%;
}
#pricing .pricing-table h1 span {
  font-size: 27px;
  margin-top: -8px;
  margin-left: 51px;
}
  #pricing .pricing-table {
    padding: 111px 45px;
    width: 180%;
    height: 96%;
    margin-left: 8%;
}
.col-lg-4 {
    width: 52.333333%;
    margin-left: 5px;
}
#features .feature-item {
    width: 535px;
    margin-left: -7px;
}
  #features .feature-item p {
    font-size: 12.6px;
}
#features .feature-item h4 {
  margin-bottom: 19px;
  margin-top: -82px;
}
#features .feature-info h4 {
  font-size: 18px;
}

#features .feature-info p {
  font-size: 14px;
}
}

@media (max-width: 567px) {
  #contact form#contactForm input[type=text], #contact form#contactForm input[type=email], #contact form#contactForm textarea {
    width: 83%;
    min-height: 39px;
    margin-left: 32px;
    align-items: center;
}
  #features .feature-item {
    border-radius: 8px;
    padding: 0px;
    margin: 9px;
    width: 370px;
    margin-left: -111px;
}
.contact-video video {
  width: 115%; /* or any specific width you want */
  height: auto;
  display: block; /* Ensures the video is visible */
}
.col-lg-4 {
  margin-left: 0px;
}
  .btn-common {
    margin-left: 26%;}
  .section {
    padding: 70px 0px; }
  #services .services-item {
    padding: 57px 60px;
    height: 91%;
    width: 100%;}
  #business-plane .business-item-info h3 {
    font-size: 25px; }
  .section-header .section-title {
    font-size: 25px; }
  #services .services-item p {
    font-size: 14px; }
  #business-plane .business-item-info p {
    font-size: 14px;
    margin-bottom: 20px; }
  .section-header .desc-text p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0; }
    #features .feature-item .feature-icon {
      margin-left: 77%;
      margin-top: 4px;
  }
  
    #features .feature-info h4 {
      font-size: 18px;
    }
  
    #features .feature-info p {
      font-size: 14px;
    }
  #pricing .pricing-table h1 span {
    left: 4%; }
    #pricing .pricing-table {
      margin-bottom: 30px;
      width: 180%;
      margin-left: 11%;
  }
  #promo-video {
    height: 250px;
  }

  .testimonial-area {
    padding: 20px;
  }

  .testimonial-item {
    width: 123%;
    margin-top: -43%;
  }
  
.right-position {
  margin-bottom: -113%;
}

  .description {
    font-size: 12px;
  }

  .author-info h5 {
    font-size: 13px;
  }
  #business-plane {
    padding-bottom: 40px; }
  .back-to-top {
    display: none !important; } 

  #pricing .pricing-table h1 span {
    left: -7%; }
    .pl-20 {
      margin-top: 28%;
      height: 692px;
  }
#features .feature-item {
  border-radius: 8px;
  padding: 0px;
  margin: 9px;
  width: 364px;
  margin-left: 0;
}
#features .feature-item p {
  font-size: 13px;
}
}
